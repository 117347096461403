import React , {useState} from 'react'
import { Select , MenuItem ,} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const DropDownMenu = ({label, value , data , name  , onSelect , errors , direction , loading}) => {
  // Responsive Design
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md")); 
    //const [value , setValue] = useState('')

    const handleChange = (e) => {
        onSelect(e.target.value)
        //setValue(e.target.value)
    }
  return (
    <section style={{width : '100%' , }}>
        <div style={{height : `${match ? "3px" : "12px"}`}}>
        <label style={{fontSize : `${match ? "16px" : "20px"}`, }}>{label}</label>
        </div>
        <br />
        <Select style={{marginTop : '20px'}} value={value} name={name} fullWidth onChange={handleChange}>
            {data?.map((item , idx) => {
               if(direction === "en"){
                return (
                  <MenuItem key={idx} value={item?.menuValueId || item?.serviceId || item?.clinicId}>{item?.menuEN?.valueEN || item?.serviceEN|| item?.clinicEN}</MenuItem>
                  )
                }else {
                  return (
                  <MenuItem key={idx} value={item?.menuValueId || item?.serviceId || item?.clinicId}>{item?.menu?.value || item?.service || item?.clinic}</MenuItem>
                )
              }
        
            })}
        </Select>
        {errors && (<p style={{color : 'red' , direction : 'rtl'}}>{errors[name]}</p>)}
    </section>
  )
}

export default DropDownMenu
