import React, { useEffect, useState } from "react";
import classes from "../../Styles/form.module.css";
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Alert,
  Fade,
} from "@mui/material";
import Textfield from "../Textfield";
import DropDownMenu from "../DropDownMenu";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { getCategory } from "../../HelperFn/getCategory";
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Form = ({ direction, data }) => {
  // Responsive Design
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  // Get The Language from Local Storage
  const { t } = useTranslation();

  const [phoneValue, setPhoneValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

 
  const validatePhoneNumber = () => {
    // Define a regular expression pattern for Saudi Arabia phone numbers
     const saudiPhoneNumberPattern =/^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
    // Check if the input matches the pattern
    const isValidPhoneNumber = saudiPhoneNumberPattern.test(phoneValue);
    setIsValid(isValidPhoneNumber);
  };

  const [textfieldValues, setTextfieldValues] = useState({
    name: "",
    birthDate: "",
  });
  const [gender, setGender] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [captcha, setRecaptcha] = useState("");
  const [maxLength,setMaxlength] = useState('')
 
  const [dropdownValues, setDropdownValues] = useState({
    city: null,
    clinic: null,
    service: null,
    nearestBranch: null,
    communicationTime: null,
    knowAboutus: null,
  });

  const handleDropdownSelect = (name, value) => {
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleTextFieldValues = (name, value) => {
    setTextfieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCaptcha = (value) => {
    setRecaptcha(value);
  };

  const [serviceData, setServiceData] = useState([]);
  const [clinicsData, setClinicsData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/Menu/Services/${dropdownValues?.clinic}`
        );

        if (response.ok) {
          const data = await response.json();
          setServiceData(data);
          setLoading(false);
        } else {
          console.error("Response not OK");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dropdownValues?.clinic]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(dropdownValues?.city);
      try {
        const response = await fetch(
          `${baseUrl}/Menu/Clinics/${dropdownValues?.city}`
        );

        if (response.ok) {
          const data = await response.json();
          setClinicsData(data);
          setLoading(false);
        } else {
          console.error("Response not OK");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dropdownValues?.city]);

  useEffect(() => {
    let phone = phoneValue?.split("") 
    if (phoneValue) {
      return phone[4] === "5" || "8" ? setMaxlength("16") : setMaxlength("14")
    }
  }, [phoneValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if (isValid) {
      // Phone number is valid, you can proceed with form submission
      setErrorMessage("");
    } else {
      // Phone number is invalid, display an error message
      setErrorMessage(t("The number must start with 5 and be 9 digits"));
      return;
    }

    const data = {
      name: textfieldValues.name,
      birthDate: textfieldValues.birthDate,
      gender: gender,
      direction: direction,
      phoneNumber: phoneValue,
      cityId: dropdownValues.city,
      clinicId: dropdownValues.clinic,
      serviceId: dropdownValues.service,
      nearestBranchId: dropdownValues.nearestBranch,
      communicationTimeId: dropdownValues.communicationTime,
      knowAboutusId: dropdownValues.knowAboutus,
    };
    const newErrors = {};
    Object.entries(data).forEach(([key, value]) => {
      if (!value && key !== "nearestBranchId") {
        newErrors[key] = `required.`; // or any custom message
      }
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      await fetch(`${baseUrl}/Patient/Patient`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });

      setShow(true);
      setPhoneValue("");
      setGender("");
      setDropdownValues({
        city: "",
        clinic: "",
        service: "",
        nearestBranch: "",
        communicationTime: "",
        knowAboutus: "",
      });
      setTextfieldValues({
        name: "",
        birthDate: "",
      });
    }
    // setErrorMessage(t("The number must start with 5 and be 9 digits"))
  };

  return (
    <section
      className={classes.container}
      style={{ direction: `${direction === "ar" ? "rtl" : "ltr"}` }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          noValidate
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Textfield
            direction={direction}
            value={textfieldValues.name}
            name="name"
            type="text"
            label={t("Name")}
            placeholder={t("Name")}
            width="100%"
            getInput={(value) => handleTextFieldValues("name", value)}
            errors={errors}
          />

          {/* Phone Number & Birth Date */}
          <section className={classes.splitTwoInputs}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: `${match ? "16px" : "20px"}`,
                  paddingBottom: "17px",
                }}
              >
                {t("Phone Number")}{" "}
              </label>
              <PhoneInput
                name="phone"
                international
                defaultCountry="SA"
                value={phoneValue}
                onChange={setPhoneValue}
                onBlur={() => validatePhoneNumber()}
                className={classes.phoneInput}
                required
                maxLength={maxLength}
                countries={["SA"]}
              />

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

              <p style={{ color: "red", direction: "rtl" }}>
                {errors?.phoneNumber}
              </p>
            </div>
            <Textfield
              value={textfieldValues.birthDate}
              name="birthDate"
              type="date"
              label={t("BirthDate")}
              placeholder={t("BirthDate")}
              width="100%"
              getInput={(value) => handleTextFieldValues("birthDate", value)}
              errors={errors}
            />
          </section>

          {/* Male OR Female */}
          <label style={{ fontSize: "20px" }}>{t("Gender")}</label>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            style={{ width: "100%", background: "white", marginTop: "15px" }}
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <FormControlLabel
              value={t("Female")}
              control={<Radio />}
              label={t("Female")}
            />
            <FormControlLabel
              value={t("Male")}
              control={<Radio />}
              label={t("Male")}
            />
          </RadioGroup>
          {errors?.gender && (
            <p style={{ color: "red", direction: "rtl" }}>{errors?.gender}</p>
          )}

          {/* Drop Down Menus */}
          <section className={classes.dropDownMenus}>
            <DropDownMenu
              direction={direction}
              value={dropdownValues.city}
              label={t("City")}
              data={getCategory(data, 3, direction)}
              //data={clincs}
              onSelect={(id) => handleDropdownSelect("city", id)}
              name="city"
              errors={errors}
            />

            <DropDownMenu
              direction={direction}
              value={dropdownValues.clinic}
              label={t("Choose the clinic")}
              // data={getCategory(data, 2, direction)}
              data={clinicsData}
              // data={clincs}
              onSelect={(id) => handleDropdownSelect("clinic", id)}
              name="clinic"
              errors={errors}
            />

            <DropDownMenu
              direction={direction}
              value={dropdownValues.service}
              label={t("Select the service")}
              data={serviceData}
              loading={isLoading}
              onSelect={(id) => handleDropdownSelect("service", id)}
              errors={errors}
              name="service"
            />

            <DropDownMenu
              direction={direction}
              value={dropdownValues.nearestBranch}
              label={t("Your nearest branch")}
              // data={clincs}
              // data={getCategory(data, 4, direction)}
              data={clinicsData}
              onSelect={(id) => handleDropdownSelect("nearestBranch", id)}
              errors={errors}
              name="nearestBranch"
            />

            <DropDownMenu
              direction={direction}
              value={dropdownValues.communicationTime}
              label={t("Preferred contact times")}
              data={getCategory(data, 6, direction)}
              //data={clincs}
              onSelect={(id) => handleDropdownSelect("communicationTime", id)}
              errors={errors}
              name="communicationTime"
            />

            <DropDownMenu
              direction={direction}
              value={dropdownValues.knowAboutus}
              label={t("How did you get to know us?")}
              data={getCategory(data, 5, direction)}
              //data={clincs}
              onSelect={(id) => handleDropdownSelect("knowAboutus", id)}
              errors={errors}
              name="knowAboutus"
            />
          </section>

          <div className={classes.captcha}>
            <ReCAPTCHA
              sitekey="6LciKMMnAAAAANO9jVtATho3jqInfT1mckWXofyu"
              onChange={handleCaptcha}
            />
          </div>

          <section className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
              disabled={!captcha}
            >
              {t("Send")}
            </Button>
          </section>

          {/* A Successfully Sent Message */}
          {show && (
            <>
              <Fade
                in={show}
                timeout={{ enter: 1000, exit: 1000 }}
                addEndListener={() => {
                  setTimeout(() => {
                    setShow((prev) => !prev);
                  }, 2000);
                }}
              >
                <Alert
                  variant="filled"
                  severity="success"
                  style={{ padding: "8px 18px", margin: "15px 0" }}
                  className="alert"
                >
                  {t("Data Has Been Sent Successfully")}
                </Alert>
              </Fade>
            </>
          )}
        </Box>
      </Box>
    </section>
  );
};

export default Form;
