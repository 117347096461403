import React , {useEffect ,useState} from 'react'
import Headline from '../Components/Home/Headline'
import Form from '../Components/Home/Form'
import Header from '../Layout/Header'
import { Container } from '@mui/material'
import BackGround from '../assets/bg.jpg'
import Chat from '../Components/Chat/Chat'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import classes from "../Styles/chat.module.css"
import { getCategory } from '../HelperFn/getCategory'
const Home = ({direction , setDirection}) => {
  const [showChatBox , setShowChatBox] = useState(false)
  
  const [dropData , setDropData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://clinicapi.mygatein.com/Menu/dropDownValues");

        if (response.ok) {
          const data = await response.json();
          setDropData(data);
        } else {
          console.error("Response not OK");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData()
  } , [])
//   const test = getCategory(dropData , 3)
//   for(const f of test){
//     console.log(f?.menuEN?.valueEN);
//    }
   return (
    <section style={{ backgroundImage: `url(${BackGround})`,position:"relative" }}>
      <Header direction={direction} setDirection={setDirection}/>
      <Container>
        <Headline direction={direction} setDirection={setDirection}/>
        <Form direction={direction} data={dropData}/>
      </Container>
      {showChatBox && (
              <div className={classes.chatBot}>
              <Chat />  
              </div>
      )}
      <div direction={direction} className={classes.chatBotIconContainer} onClick={() => setShowChatBox((prev) => !prev)}>
        <ChatBubbleIcon className={classes.chatBotIcon} style={{fontSize : "40px" }}/>
      </div>
    </section>
  );
}

export default Home