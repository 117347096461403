/* eslint-disable import/no-anonymous-default-export */
export default {
    ar :{
        translation:{
            "Welcome To " : "مرحبا بك في",
            "Please Fill This Form To Help You The Best Way We Can" : "الرجاء ملء الفورم حتي نتمكن من مساعدتك ",
            "Name" : "الاسم",
            "BirthDate" : "تاريخ الميلاد",
            "Phone Number" : "رقم الجوال",
            "Gender" : "الجنس",
            "City" : "المدينة",
            "Choose the clinic" : "اختر العياده",
            "Select the service" : "اختر الخدمه",
            "Your nearest branch" : "اقرب فرع لك",
            "Preferred contact times" : "اوقات التواصل المفضله",
            "How did you get to know us?" : "كيف تعرفت علينا",
            "Send" : "ارسال",
            "Male" : "ذكر",
            "Female" : "انثي",
            "Data Has Been Sent Successfully" : "تم إرسال البيانات بنجاح",
            "Hello! I am your Dentist  How can I assist you today?":"مرحبًا! أنا طبيب أسنانك كيف يمكنني مساعدتك اليوم؟",
            "view Appointments":"المواعيد المتاحة",
            "dental Services":"خدمات طب الأسنان",
            "contact Information":"معلومات الاتصال",
            "end chat":"انهاء المحادثه",
            "upcoming Appointments":"المواعيد القادمة",
            "Tue Sep 05 2023 14:24:10":"الثلاثاء 05 سبتمبر 2023 الساعة 14:24:10",
            "We Sep 06 2023 18:24:10":"الاربعاء 06 سبتمبر 2023 18:24:10",
            "Th Sep 06 2023 18:24:10":"06 سبتمبر 2023 الساعة 18:24:10",
            "dental ServicesList":"قائمة خدمات طب الأسنان",
            "teeth Cleaning":"تنظيف الأسنان",
            "fillings":"الحشوات",
            "tooth Extractions":" قلع الأسنان",
            "root Canals":" قنوات الجذر",
            // "dental ServicesList":"قائمة خدمات طب الأسنان",
            "contact Info Label":"تسمية معلومات الاتصال",
            "address":"عنوان",
            "phone":"02484858",
            // "address":"عنوان عيادة طبيب الأسنان: 123 الشارع الرئيسي، المدينة",
            "The number must start with 5 and be 9 digits":"يجب ان يبدا الرقم 5 وان تكون 9 ارقام"
        }
    }
}