export const getCategory = (data , value) => {
    const arr = []
    for(let cate of data) {
        if(cate.menuId === value){
            // if(direction === "en"){
            //     arr.push(cate?.menuEN)
            // }else{
            //     arr.push(cate?.menu)
            // }
            arr.push(cate)
        }
    }
    return arr
}

