import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const Textfield = ({
  name,
  value,
  label,
  type,
  placeholder,
  width,
  getInput,
  errors,
}) => {
  // Responsive Design
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  const lang = localStorage.getItem("lang");

  const handleValue = (e) => {
    const newValue = e.target.value;

    // Regex pattern to match Arabic characters or characters from other non-English languages.
    const arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
    const englishPattern = /[a-zA-Z]/;

    if (lang === "en" && arabicPattern.test(newValue)) {
      alert("English mode: Please do NOT enter Arabic characters.");
      return;
    }

    if (lang === "ar" && englishPattern.test(newValue)) {
      alert("Arabic mode: Please do NOT enter English characters.");
      return;
    }

    getInput(newValue);
  };

  return (
    <section style={{ width: "100%" }}>
      <label style={{ fontSize: `${match ? "16px" : "20px"}` }}>{label}</label>
      <br />
      <TextField
        value={value}
        margin="normal"
        required
        type={type}
        name={name}
        placeholder={placeholder}
        InputLabelProps={{ shrink: true }}
        size="big"
        style={{ width: `${width}`, background: "white" }}
        onChange={handleValue}
      />
      {errors && (
        <p style={{ color: "red", direction: "rtl" }}>{errors[name]}</p>
      )}
    </section>
  );
};

export default Textfield;
