import React from 'react'
import classes from '../../Styles/headline.module.css'
import { useTranslation, I18nextProvider } from 'react-i18next';
const Headline = ({direction , setDirection}) => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className={classes.container}
      style={{ direction: `${direction === "ar" ? "ltr" : "rtl"}` }}
    >
      <h3 className={classes.headline1}>{t("Welcome To")}</h3>
      <h1 className={classes.headline2}>Niyat - نياط</h1>
      <h3 className={classes.headline3}>
        {t("Please Fill This Form To Help You The Best Way We Can")}
      </h3>
    </section>
  );
}

export default Headline